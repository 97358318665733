.AllCampaignStats {
  display: flex;
  gap: 4px;
}

.CampaignStatCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #09090b;
  /* gap: 4px; */
}
.CampaignStatCard.in-campaign-table {
  min-height: 100px;
}
.CampaignStatCard.interactive {
  cursor: pointer;
}
.CampaignStatCard.interactive:hover {
  background-color: #e5e7eb44;
}
.CampaignStatCard.selected {
  border: 1px solid var(--primary-color);
}

.campaign-stat-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.campaign-stat-card-title-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.campaign-stat-card-content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* gap: 6px; */
}

.campaign-stat-card-value {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #000;
}

.campaign-stat-card-extra {
  font-size: 12px;
  color: #71717a;
}

.CampaignData {
  display: flex;
  /* gap: 48px; */
  gap: 16px;
  width: 100%;
  height: 100%;
  /* padding: 24px 48px; */
  padding: 24px 32px;
  box-sizing: border-box;
  overflow-y: auto;
}

.campaign-data-main-container {
  width: 100%;
}
.campaign-data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.campaign-data-name-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-data-name {
  font-size: 20px;
  font-weight: 600;
}
.campaign-data-section-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 0;
}
.campaign-data-stat-container {
  display: flex;
  gap: 4px;
}

.envio-data-stat-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
}

.campaign-data-side-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 40vw;
  height: 100%;
  padding-left: 16px;
  border-left: 1px solid #e5e7eb77;
}
.campaign-data-side-title {
  font-size: 18px;
  font-weight: 500;
}
.campaign-data-side-info {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.campaign-data-side-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.CampaignDataSummary {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.campaign-data-summary-label {
  color: #71717a;
}
.campaign-data-summary-value {
  color: #000;
  font-weight: 500;
}

.CampaignDataPerformance {
  display: flex;
  gap: 16px;
}

.campaign-data-performance-sider {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 40%;
  padding: 16px;
  border-right: 1px solid #e5e7eb77;
}

.CampaignDataDonut {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-bottom: 16px;
  /* border-bottom: 1px solid #e5e7eb77; */
}
.campaign-data-donut-title {
  font-size: 16px;
  font-weight: 500;
}

.CampaignDataTime {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  height: fit-content;
}
.campaign-data-time-title {
  font-size: 16px;
  font-weight: 500;
}
.campaign-data-time-tooltip {
  padding: 8px;
  border-radius: 8px;
  background-color: #fff;
}
.campaign-data-time-tooltip-title {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
}
.campaign-data-time-tooltip-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}
.campaign-data-time-tooltip-color {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
.campaign-data-time-tooltip-label {
  font-size: 12px;
  color: #71717a;
}
.campaign-data-time-tooltip-value {
  display: block;
  margin-left: 8px;
  font-size: 12px;
}

.CampaignDataKPIs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}

.campaign-data-funnel-title {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  font-weight: 500;
}

:root {
  --color-gray: #6c757d;
  --color-light-gray: #e9ecef;
}

.campaign-data-funnel-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.campaign-data-funnel-timeline {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas:
    'phase1 . phase2 . phase3'
    'line line line line line'
    '. negative1 . negative2 .';
  min-height: 200px;
  align-items: end;
}

.CampaignDataFunnelPhase {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
.campaign-data-funnel-phase-title {
  /* text-decoration: underline solid; */
  border-bottom: 1px solid;
  width: 100%;
  text-align: center;
}
.campaign-data-funnel-phase-metric-container {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
}
.campaign-data-funnel-phase-metric-container > * {
  flex: 1;
}
.phase1 {
  grid-area: phase1;
  margin-left: 32px;
}
.phase2 {
  grid-area: phase2;
}
.phase3 {
  grid-area: phase3;
  margin-right: 32px;
}

.CampaignDataFunnelMetric {
  border: 1px solid;
  border-radius: 0.5rem;
  padding: 0.5rem;
  min-width: max-content;
  width: 100%;
  max-width: 180px;
  box-shadow: 1px 1px 2px 1px #ccc;
}
.campaign-data-funnel-metric-header {
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-data-funnel-metric-label {
  font-size: 0.875rem;
  color: var(--color-gray);
}
.campaign-data-funnel-metric-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.CampaignDataFunnelMetric.negative {
  margin-top: 8px;
  margin: auto;
}
.CampaignDataFunnelMetric.negative.negative1 {
  grid-area: negative1;
}
.CampaignDataFunnelMetric.negative.negative2 {
  grid-area: negative2;
}

.campaign-data-funnel-timeline-wrapper {
  position: relative;
  grid-area: line;
}
.campaign-data-funnel-timeline-line {
  position: absolute;
  width: 95%;
  /* border-top: 2px dashed var(--color-light-gray); */
  border-top: 2px dashed rgba(0, 0, 0, 0.88);
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
.campaign-data-funnel-timeline-points {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.campaign-data-kpis-container {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 16px;
}

.CampaignDataKPICard {
  display: flex;
  flex-direction: column;
}

.campaign-data-settings-drawer-input-label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.CampaignDataSmartAnalysis {
  margin-bottom: 32px;
}

.envio-data-side-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb77;
}
.envio-data-side-info.oneColumn {
  grid-template-columns: repeat(1, 1fr);
}

.envio-data-graph {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  height: fit-content;
}

@media (min-width: 1600px) {
  .envio-data-stat-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
