.landing-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  width: 100%;
  padding: 5% 5%;
  justify-content: space-between;
  background-color: inherit;
  /* min-height: calc(100dvh - 5rem); */
  font-family: "Space Grotesk", sans-serif;
}

.landing-section-clientes {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.landing-section-sabiasque {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  color: white;
  background: linear-gradient(
    to bottom,
    var(--landing-background-color) 50%,
    var(--landing-highlight-background) 50%
  );
}

.landing-datos-upper-text {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-family: "Space Grotesk", sans-serif;
}

.landing-font-highlight {
  color: var(--landing-highlight-color);
}

.landing-linea-blanca {
  height: 1px;
  width: 2.25rem;
  background-color: white;
}

.landing-section-title {
  font-weight: 800;
  font-size: 3.25rem;
  line-height: 3.25rem;
  width: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  /* color: white; */
  font-family: "Plus Jakarta Sans", sans-serif;
  width: fit-content;
  max-width: 100%;
}

.landing-section-title-sm {
  font-weight: 800;
  font-size: 2.75rem;
  line-height: 2.75rem;
  width: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  /* color: white; */
  font-family: "Plus Jakarta Sans", sans-serif;
  max-width: 1000px;
  /* width: fit-content; */
}

.landing-section-subtitle {
  font-family: "Space Grotesk", sans-serif;
  margin: 2.5rem 0px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.landing-section-subtitle-grande {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin: 3.5rem 0px;
  font-weight: 500;
}

.landing-section-column {
  display: flex;
  flex-direction: column;
}

.landing-section-column:nth-child(1) {
  width: 50%;
}
.landing-section-column:nth-child(2) {
  width: 50%;
  /* display: flex;
  flex: 1;
  height: 100%; */
}

.landing-boton-generico {
  padding: 1rem 1.5rem;
  border-radius: 36px;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  gap: 16px;
  max-height: 50px;
  height: auto;
  border: none;
  width: fit-content;
  cursor: pointer;
  box-shadow: none;
}

.landing-boton-generico:hover {
  background-color: white;
  color: var(--landing-background-color);
  box-shadow: none;
}

.landing-boton-bordered {
  background-color: var(--landing-background-color);
  border: 1px solid white;
  color: white;
}

.section-boxes-layout {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin-top: 3rem;
}

.section-box {
  border-radius: 12px;
  padding: 1rem;
  color: var(--landing-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  /* width: fit-content; */
  max-width: 100%;
  font-family: "Plus Jakarta Sans", sans-serif;
  z-index: 2;
  min-height: 6rem;
}

.section-box:nth-child(1) {
  background-color: white;
  max-width: 65%;
}

.section-box:nth-child(2) {
  background-color: var(--landing-highlight-color);
  max-width: 75%;
}

.section-box:nth-child(3) {
  background-color: var(--landing-highlight-background);
  max-width: 85%;
}

.section-box:nth-child(4) {
  background-color: var(--landing-highlight-dark);
  max-width: 95%;
}

.section-box-title,
.section-box-title > strong {
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  /* vertical-align: top; */
}

.section-box-subtitle {
  font-size: 1.1rem;
  line-height: 1.15rem;
  /* word-break: break-all; */
}

.landing-image-1 {
  overflow: visible;
  /* height: 100%; */
  /* transform: scale(1.05) translate(-5%, 0); */
  width: 100%;
  object-fit: cover;
}

.landing-image-2 {
  overflow: visible;
  /* height: 100%; */
  object-fit: cover;
}

.landing-logos {
  overflow: hidden;
  padding: 8px 0px;
  margin: 5rem 0px;
  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  white-space: nowrap;
  position: relative;
  /* gap: 2rem; */
}

.landing-clientes-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-inline: auto;
  gap: 2rem;
  width: max-content;
  animation: 20s slide infinite linear;
}

.landing-clientes-logos img {
  height: calc(100vw / 30);
  flex-shrink: 0;
}

.landing-logos:after,
.landing-logos:before {
  position: absolute;
  top: 0;
  width: 20%;
  height: 100%;
  content: "";
  z-index: 2;
}

.landing-logos:before {
  left: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    var(--landing-background-color)
  );
}

.landing-logos:after {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--landing-background-color)
  );
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.landing-bottom-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: stretch;
  background-color: white;
  border-radius: 2rem;
  width: 100%;
  padding: 4rem;
  /* transform: translate(0px, -8rem); */
  height: 100%;
  position: relative;
}

.landing-bottom-box-column {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  color: var(--landing-background-color);
  text-align: left;
  height: 100%;
  justify-content: space-between;
}

.landing-bottom-title {
  font-size: 1.75rem;
  line-height: 1.75rem;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.landing-bottom-img {
  width: 6rem;
}

.landing-bottom-layout {
  display: flex;
  flex-direction: row;
  width: calc(50% - 0.5rem);
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  gap: 1rem;
}

.landing-bottom-divider {
  height: 100%;
  width: 1px;
  background-color: #d8d8d8;
  margin-inline: 1rem;
}

/* .landing-bottom-highlight-background {
  background-color: var(--landing-highlight-background);
  width: 100vw;
  padding: 0px 5%;

  transform: translate(0px, 3rem);
  margin-top: 5rem;
} */

.landing-bottom-image-absolute {
  width: 17%;
  position: absolute;
  right: 0px;
  top: 35%;
  transform: translate(-20%, -50%);
  z-index: 1;
}

.landing-sabias-que-img {
  position: absolute;
  height: 80%;
  top: 10%;
}

.landing-boton-light-bordered {
  color: var(--landing-background-color);
  background-color: white;
  border: 1px solid var(--landing-background-color);
}

.landing-boton-light-bordered:hover {
  color: white;
  background-color: var(--landing-background-color);
}

@media (max-width: 1280px) {
  .landing-section-title-sm {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

@media (max-width: 1000px) {
  .landing-sabias-que-img {
    display: none;
  }
}

@media (max-width: 768px) {
  .landing-section {
    flex-direction: column;
    padding-bottom: 3rem;
    justify-items: stretch;
  }

  .landing-section-column:nth-child(1),
  .landing-section-column:nth-child(2) {
    width: 100% !important;
  }

  .landing-section-column:nth-child(2) {
    margin-top: 3rem;
  }

  .section-boxes-layout {
    margin-top: 1.5rem;
  }

  .landing-bottom-box {
    flex-direction: column;
    gap: 3rem;
  }

  .landing-bottom-image-absolute {
    width: 25%;
    top: 40%;
    right: 0px;
    transform: none;
    display: none;
  }

  .landing-bottom-layout {
    flex-direction: column;
    width: 100%;
    align-items: center;
    /* text-align: center; */
  }

  .landing-bottom-box-column {
    align-items: center;
    text-align: center;
    margin-top: 1.5rem;
  }

  .landing-clientes-logos img {
    height: calc(100vw / 15);
  }
}
