.first-registry-wrapper {
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 8px;
}

.sin-instancia-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0px;
  /* border: 1px solid #d8d8d8; */
  border-radius: 8px;
  padding: 24px;
  height: fit-content;
  width: 100%;
  max-width: 500px;
  overflow-y: auto;
}

.form-validacion-email {
  padding: 24px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 100%;
}

.inputs-auth-code {
  width: 50px;
  max-width: calc(95% / 6);
  height: 50px;
  margin: 8px;
  border: 1px solid #d0d0d0;
  text-align: center;
  font-size: 20px;
  border-radius: 4px;
}

.inputs-auth-code:focus {
  outline: none !important;
  border: none;
  box-shadow: 0 0 0 3px #77be4277;
}

.container-auth-code {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.workspaces-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 16px;
  padding: 25vh;
}
.workspace-logout {
  color: #1e2c39;
  margin-top: auto;
  margin-inline: auto;
  margin-bottom: 24px;
  padding-top: 24px;
}

.workspace-logout .ant-btn:hover {
  color: #7db828 !important;
  border: none !important;
}

.workspace-logout .ant-btn:focus {
  color: #7db828 !important;
  border-color: none !important;
}

.workspace-logout .ant-btn-primary {
  background-color: #7db828 !important;
  border-color: none !important;
  color: white !important;
}

.workspaces-view-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  animation: popup-pick-instance 300ms ease, smooth-appear 1s ease forwards;
}

.instance-main-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.alta-pending-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--landing-background-color);
  color: white;
  overflow-y: auto;
  gap: 2rem;
  padding-top: 2rem;
}

@keyframes popup-pick-instance {
  0% {
    transform: scale(80%);
  }

  100% {
    transform: scale(100%);
  }
}

.workspace-card {
  width: 90px;
  height: 90px;
  padding: 10px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invitations-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0px;
  max-height: 130px;
  overflow-y: auto;
}

.invitation-row {
  padding: 6px 8px;
  background-color: #f8f8f8;
  font-size: 14px;
  border-radius: 8px;
}

.card-workspace {
  width: 90px;
  height: 90px;
  margin: 8px;
  display: grid;
  place-items: center;
  text-align: center;
  max-width: 100%;
}

.card-workspace .ant-card-body {
  width: 100%;
}

.card-contenedor-workspace {
  width: 100%;
  max-width: min(472px, 100%);
  display: flex;

  justify-content: center;
  align-items: center;
  border: none;
}

.card-contenedor-workspace .ant-card-body {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.workspaces-divider {
  height: 1px;
  width: calc(100% - 64px);
  max-width: 100%;
  background-color: #f0f0f0;
}

.workspaces-main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: min(472px, 100%);
}

.registro-left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 100%;
  max-width: 100%;
  flex: 1;
}

.registro-titulo {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.registro-subtitulo {
  font-family: "Space Grotesk", sans-serif;
}

.registro-background-wrapper {
  width: 50%;
  height: 100%;
  background-color: #1e2c39;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.registro-image {
  height: 70%;
}

.registro-brocoly-logo {
  position: absolute;
  width: 15%;
  top: 2rem;
  left: 2rem;
}

.validar-mail-wrapper {
  height: 100%;
  width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  background-color: var(--landing-background-color);
  overflow: hidden;
  /* overflow-y: auto; */
}

.validar-mail-fila {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  /* max-height: 100%; */
}

.imagen-codigo {
  width: 40%;
}

@media (max-width: 1000px) {
  .registro-background-wrapper {
    display: none;
  }

  .imagen-codigo {
    display: none;
  }
}
