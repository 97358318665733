:root {
  --input-color: #037cae;
  --whatsapp-color: #1bd741;
  --cycle-color: #cd7574;
  --condition-color: #e98543;
  --end-color: #595959;
  --branch-color: rgb(244, 184, 0);
  --hover-color: #78be4218;
  --jump-color: #ce7dd4;
  --selected-color: #dce056;
  --primary-color: #77be42;
  --dark-color: #1d2c39;
  --card-bot-gray-color: #8a8c8f;

  /* VARIABLES LANDING PAG */

  --landing-background-color: #1d2d3a;
  --landing-highlight-color: #e3df26;
  --landing-highlight-background: #c2d11a;
  --landing-highlight-dark: #7eb628;
}

.main-wrapper-flow {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.end-node,
.node {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  padding: 2px;
  border-radius: 50%;
  color: #fff;
}
.each-node-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #f8f8f888;
  border-radius: 6px;
  border: 1px solid #a7a6a6;
  max-width: 300px;
  box-sizing: border-box;
  color: #252525;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  position: relative;
}
.node-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  padding: 0px 8px;
  margin-bottom: 8px;
  min-width: 175px;
}
.node-header-send-template {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  padding: 0px 8px;
}
.start-node {
  background-color: white;
}
.mensaje-nodo {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #252525;
  font-size: 15px;
  font-weight: 700;
  width: 100%;
}
.end-node {
  background-color: var(--end-color);
}
.condition-node {
  background-color: var(--condition-color);
  height: 50px;
  width: 100px;
  border-radius: 8px;
  cursor: default;
}
.input-node {
  background-color: var(--input-color);
}
.answer-node {
  color: var(--whatsapp-color);
}
.cycle-node {
  background-color: var(--cycle-color);
}
.branch-node {
  background-color: var(--branch-color);
}
.jump-node {
  background-color: var(--jump-color);
}
.custom-drag-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  margin: 12px 0px 8px 0px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  height: fit-content;
  width: fit-content;
}
.custom-drag-item {
  padding: 8px;
}
.flow-builder-content {
  border-radius: 8px !important;
}
.titulo-drag-drop {
  font-size: 1.2rem;
}
.option-node-wrapper {
  display: flex;
  flex-direction: row;
  padding: 8px;
  width: 220px;
  gap: 8px;
  border: 1px dashed #d8d8d8;
  border-radius: 8px;
  cursor: pointer;
}
.option-node {
  color: white;
  border-radius: 50%;
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
}
.option-node-wrapper > .option-node .condition-node {
  background-color: var(--condition-color);
}
.option-node-wrapper > .option-node {
  background-color: white !important;
  color: #252525;
  border: 1px solid #252525;
}
.option-node-wrapper:hover > .option-node.input-node {
  background-color: var(--input-color) !important;
  color: white;
  border-color: var(--input-color);
  -moz-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.option-node-wrapper:hover > .option-node.answer-node {
  background-color: var(--whatsapp-color) !important;
  color: white;
  border-color: var(--whatsapp-color);
  -moz-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.option-node-wrapper:hover > .option-node.branch-node {
  background-color: var(--branch-color) !important;
  color: white;
  border-color: var(--branch-color);
  -moz-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.option-node-wrapper:hover > .option-node.cycle-node {
  background-color: var(--cycle-color) !important;
  color: white;
  border-color: var(--cycle-color);
  -moz-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.option-node-wrapper:hover > .option-node.condition-node {
  background-color: var(--condition-color) !important;
  color: white;
  border-color: var(--condition-color);
  -moz-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.option-node-wrapper:hover > .option-node.jump-node {
  background-color: var(--jump-color) !important;
  color: white;
  border-color: var(--jump-color);
  -moz-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.option-node-wrapper:hover {
  background-color: var(--hover-color);
  -moz-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.titulo-nodo {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.columna-descripcion {
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  gap: 4px;
  color: #252525;
}
.custom-drop {
  background-color: white;
  border: 1px solid #037cae;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.triggers-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.trigger-item {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  width: fit-content;
  max-width: 100%;
}
.delete-trigger {
  display: flex;
  cursor: pointer;
  color: #252525;
  padding-left: 5px;
  border-left: 1px solid #d4b106;
  width: fit-content;
}
.trigger-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  min-height: 24px;
  gap: 7px;
  max-width: 100% !important;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
}

.trigger-tag > p {
  flex: 1;
  max-width: 100%;
  white-space: normal;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linea-esperar-switch {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;
}
.form-nodos .radio-media .ant-radio-inner {
  width: 16px !important;
  height: 16px !important;
}
.node-preview-wrapper {
  width: calc(100% - 48px);
  position: absolute;
  bottom: 24px;
  left: 24px;
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    url("/public/assets/wsp-background.png");
  border-radius: 8px;
  min-height: 150px;
  padding: 8px;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
}
.node-forms-wrapper {
  height: calc(100% - 308px);
  overflow-y: auto;
}
.node-forms-wrapper::-webkit-scrollbar {
  display: none;
}
.common-node-forms {
  height: 100%;
  width: 100%;
}

.ant-drawer-body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ant-drawer-body::-webkit-scrollbar {
  display: none;
}

.condition-form-wrapper {
  padding: 8px;
  border: 1px dashed #c8c8c8;
  border-radius: 8px;
  margin: 16px 0px;
  position: relative;
}
.react-flow__handle {
  width: 10px;
  height: 10px;
  background: #b1b1b7;
  border: 1px solid #252525;
}

.react-flow__handle::after {
  content: "";
  position: absolute;
  width: 24px; /* Larger invisible hit area */
  height: 24px; /* Larger invisible hit area */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.react-flow__handle-left {
  top: 15%;
  left: -5px;
}
.react-flow__handle-right {
  top: 50%;
  right: 0px;
  transform: translate(-50%, -50%);
  background-color: #d9d9d9;
}
.react-flow__handle-top {
  left: 95%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.types-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 0px;
}
.types-category-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.type-item {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin: 4px 0;
  padding: 12px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  cursor: pointer;
}
.type-item-text-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.selected {
  border-color: var(--whatsapp-color);
}
.node-preview-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.node-buttons-layout {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.node-type {
  font-size: 12px;
}
.node-description {
  display: flex;
  flex-direction: column;
  width: calc(100% - 42px);
}
.path-id {
  padding: 2px 4px;
  font-weight: 700;
  align-self: flex-start;
  border: 1.5px solid var(--whatsapp-color);
  border-radius: 4px;
  color: var(--whatsapp-color);
  margin-top: 12px;
  background-color: white;
  font-size: 14px;
  line-height: 14px;
}
.node-bubble {
  width: 100%;
  background: #fff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #c6c5c5;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 4px;
}
.main-container-handles {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.contenedor-handles {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin-top: 10px;
}
.contenedor-handles.top-container {
  top: 0px;
  bottom: auto;
  margin-top: 0;
}
.linea-handle {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 8px 10px;
}
.custom-handle {
  right: -10px;
}
.next-step {
  border-color: #1bd741;
  background-color: white;
}
.fallback {
  border-color: #ff4a44;
  background-color: white;
}
.trigger-handle {
  border-color: #1bd741;
  background-color: white;
}
.esperar-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0px 2px;
  /* background-color: white;
  border: 1px dashed #0cb112; */
  color: #0cb112;
  /* max-width: ; */
  border-radius: 6px;
  gap: 4px;
  font-size: 12px;
  margin: 12px 0px;
  overflow: hidden;
  align-self: flex-start;
}
.esperar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.mensaje-entrante {
  display: flex;
  flex-direction: column;
  border: 1px solid #c6c5c5;
  background-color: white;
  padding: 4px;
  border-radius: 6px;
  max-width: 100%;
}
.mensaje-entrante-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  max-width: 100%;
}
.triggers-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 200px;
  margin-top: 8px;
  gap: 4px;
}
.flow-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding: 12px;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  width: 100%;
  box-sizing: border-box;
}
.flow-name {
  max-width: 300px;
  text-overflow: ellipsis;
}
.panel-flow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.confirm-wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 998;
  top: 0px;
  left: 0px;
}
.confirm-form {
  background-color: white;
  width: clamp(300px, 400px, 90%);
  border-radius: 8px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 90%;
  box-sizing: border-box;
}
.errors-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100% - 100px);
  overflow-y: auto;
}
.form-title {
  /* font-size: 20px; */
  font-weight: 500;
  font-size: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.custom-switch-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
  gap: 6px;
  height: 24px;
}
.page-preview-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  /* border-bottom: 1px solid #d8d8d8; */
  padding: 4px;
  background-color: #f5f5f5;
  border-radius: 8px;
}
.page-preview-left {
  padding: 2px;
}
.page-preview-right {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  max-width: calc(100% - 112px);
}
.full {
  max-width: 100%;
}
.page-preview-right span {
  word-wrap: break-word;
  white-space: pre-wrap;
}
.label-secciones {
  font-weight: 600;
}

.botones-con-linea {
  overflow: visible;
}

.button-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  min-width: 60px;
  gap: 4px;
  color: #00a5f4;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  background-color: #fff;
}

.linea-trucha {
  position: absolute;
  right: -10px;
  top: 50%;
  height: 1px;
  width: 15px;
  background-color: #b1b1b1;
  transform: translate(-0%, -50%);
}
.linea-trucha-lista {
  position: absolute;
  right: -18px;
  top: 50%;
  height: 1px;
  width: 18px;
  background-color: #b1b1b1;
  transform: translate(-0%, -10%);
}
.handle-date-time {
  right: -10px;
}

.template-crear-lista {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0px;
  background-color: #f8f8f8;
  padding: 8px;
  border-radius: 8px;
}
.lista-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* background-color: white; */
  border-radius: 8px;
}
.horizontal-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.item-wrapper {
  /* font-size: 14px; */
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.node-list-layout {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* background-color: white; */
  border-radius: 8px;
  padding: 8px;
  background-color: #ffffff88;
  border: 1px solid #e8e8e8;
}
.titulo-lista {
  display: flex;
  align-items: center;
  color: #00a5f4;
  justify-content: center;
  gap: 4px;
  margin-bottom: 4px;
  /* padding: 4px; */
}
.list-seccion {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
  width: 100%;
}
.list-seccion-title {
  color: #00a5f4;
  background-color: rgba(0, 165, 244, 0.08);
  padding: 2px;
  border-radius: 4px;
  font-size: 12px;
}
.list-row {
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  position: relative;
  width: 100%;
}
.list-row-title {
  color: #252525;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.list-row-id {
  padding: 0px 4px;
  font-weight: 500;
  border: 1px solid #62a4c0;
  color: #62a4c0;
  border-radius: 4px;
}
.list-row-description {
  color: #a0a0a0;
  font-size: 12px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 0px;
}
.list-item-handle {
  right: -5px;
  background-color: white;
}

.form-item-column {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 16px;
}
.error-div {
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 16px;
}
.variables-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 400px;
  max-height: 40vh;
  overflow-y: auto;
  border-radius: 8px;
  padding-right: 8px;
}
.variables-linea {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 8px;
}
.custom-tag {
  border-radius: 4px;
  border: 1px solid #b7eb8f;
  color: #389e0d !important;
  background: #f6ffed;
  font-size: 14px;
  line-height: 14px;
  padding: 2px 7px;
}
.ant-tag {
  margin-inline-end: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}
.box-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  font-size: 12px;
  color: #252525;
}

.box-info-array-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.ant-input-group .ant-input-group-addon {
  background-color: #f6ffed !important;
  color: #389e0d !important;
  border-color: #b7eb8f !important;
}

.acciones-nodos-wrapper {
  position: absolute;
  top: -34px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.accion-nodo {
  display: grid;
  place-items: center;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  background-color: white;
  cursor: pointer;
}

.branch-conditions-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding-left: 8px;
  border-left: 1px dashed var(--primary-color);
  margin-left: 24px;
  margin-bottom: 16px;
}
.case-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  position: relative;
  width: 92%;
  transform: translate(0px, 50%);
}
.case-display span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-default {
  color: white;
  background-color: #434141;
  border-color: #434141;
}
.linea-cases {
  width: 8px;
  border-top: 1px dashed var(--primary-color);
  position: absolute;
  left: -8.5px;
}
.time-conditions-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.condition-time-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px dashed var(--dark-color);
  padding: 8px;
  border-radius: 8px;
}
.dias-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  position: relative;
}
.dia-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: var(--dark-color);
  background-color: white;
  border: 1px solid var(--dark-color);
  cursor: pointer;
}
.dia-selected {
  color: white;
  background-color: var(--dark-color);
}
.borrar-horario {
  position: absolute;
  right: -4px;
  top: -4px;
  cursor: pointer;
}

.ant-picker-dropdown .ant-picker-ranges {
  padding: 4px auto;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
}
.ant-picker-dropdown .ant-picker-ranges .ant-picker-ok {
  margin-inline-start: 0px;
}
.form-custom-footer {
  position: absolute;
  bottom: 0px;
  left: 24px;
  width: calc(100% - 48px);
  padding: 8px 0px 24px 0px;
  background-color: white;
  margin-bottom: 0px !important;
  overflow: hidden;
  z-index: 2;
}
.form-nodos {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 70px;
  overflow-y: auto;
  -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
}

.form-nodos::-webkit-scrollbar {
  display: none;
}

.node-time-granularity {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
}
.case-time {
  transform: translate(0px, 12px);
}
.transcribe-audio-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  position: relative;
  margin-top: 8px;
}
.transcribe-audio-column .ant-tag {
  z-index: 9999;
}
.linea-transcribe-audio {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0px;
  left: 50%;
  border-left: 1px dashed var(--primary-color);
}
.fila-acciones {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
  justify-content: center;
}
.icono-acciones {
  font-size: 18px;
  cursor: pointer;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icono-acciones > span {
  height: 18px;
}

.fila-triggers {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: white;
  border-radius: 8px;
  padding: 8px;
}
.headers-wrapper {
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.request-content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
}
.request-content-info {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
.text-wrap {
  line-height: 20px;
  max-height: 150px;
  width: 100%;
  white-space: wrap;
  word-break: break-all;
  margin-top: 16px;
  text-overflow: ellipsis;
  font-size: 12px;
  font-family: "Courier New", Courier, monospace;
}

.monospace-font {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.template-body-wrapper {
  gap: 4px;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.variables-drawer {
  display: flex;
  width: 450px;
  position: absolute;
  background-color: red;
  height: 20%;
}
.drawer-vars {
  background-color: white;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.template-section-value {
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.04);
  color: #252525;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  word-break: break-word;
  white-space: break-spaces;
  max-width: 100%;
}
.options-template-variables {
  flex: 1 1 120px;
}
.options-template-variables .ant-select-selector {
  border-radius: 0px 6px 6px 0px !important;
}
.prefix-select {
  background-color: var(--dark-color);
  color: white;
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0px 0px 6px;
}
.variables-body-wrapper {
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 16px;
}
.variables-body-header-wrapper {
  background-color: #f8f8f8;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 0px;
}
.variables-body-header-wrapper :nth-child(1) {
  width: calc(30% - 16px);
}
.variables-body-header-wrapper :nth-child(2) {
  width: calc(42% - 16px);
}
.variables-body-header {
  padding: 8px 8px 4px 8px;
}
.variable-body-item {
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f8f8f8;
  /* gap: 4px; */
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

.variable-body-item > :nth-child(1) {
  width: 30%;
}
.variable-body-item > :nth-child(2) {
  width: 42%;
}
.variable-body-item > :nth-child(3) {
  width: 22%;
}

.label-duplicate {
  margin-right: auto;
  margin-bottom: 4px;
  font-weight: 600;
}
.horizontal-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}
.speech-text-wrapper {
  box-sizing: border-box;
  padding: 4px;
  border: 1px solid #f0f0f0;
  max-width: 100%;
  border-radius: 6px;
  background-color: white;
}
.speech-text {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 4px 4px;
}

pre {
  margin: 0px;
}

b {
  font-weight: 600;
}

.encode-node-content {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.encode-form-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
}

.boton-indice {
  position: absolute;
  left: 8px;
  /* height: 100%;

  transform: translate(0% 50%); */
}

.template-node-data {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  margin: 8px 0px;
  justify-content: center;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.formula-node-description {
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.formula-node-description > b {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .item-row label {
  width: 100%;
} */

.item-row label::after {
  display: none;
}

.select-trigger-modal-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.SelectTriggerHelp {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
}

.select-trigger-card-container {
  display: flex;
  justify-content: space-evenly;
  gap: 24px;
}

.SelectTriggerCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: clamp(200px, 325px, 50%);
  margin: 8px 0;
  padding: 16px;
  border: 2px dashed #8a8c8f99;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.SelectTriggerCard:hover {
  border-color: var(--primary-color);
  background-color: var(--hover-color);
}

.empty-bots-list {
  margin: auto;
  margin-top: 50px;
}

.contador-bots {
  margin: 16px 0;
}

.bots-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  width: 85%;
  margin-inline: auto;
}

.cards-bots-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 8px;
  max-width: 85%;
  margin-inline: auto;
  grid-auto-rows: 1fr;
}

.CardBot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  border: 1px solid #d8d8d8bb;
  border-radius: 8px;
  font-size: 13px;
  width: 100%;
}

.card-bot-first-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  flex-grow: 1;
}

.card-bot-first-row {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  overflow: hidden;
}

.card-bot-name {
  font-weight: 600;
  /* font-size: 16px; */
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.card-bot-description {
  color: var(--card-bot-gray-color);
  margin-bottom: 8px;
}

.card-bot-data-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card-bot-row {
  display: flex;
  align-items: center;
  gap: 8px;
}
.card-bot-row.row-created {
  align-items: start;
}

.card-bot-triggers-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  margin: 8px 0px;
  align-items: center;
  flex-grow: 1;
}

.card-bot-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  min-height: 40px;
}

.card-bot-label {
  color: var(--card-bot-gray-color);
}

.CardBotMetrics {
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
}
.card-bot-metrics-container {
  display: flex;
  align-items: baseline;
  gap: 4px;
}
.card-bot-metrics-percent {
  font-size: 1.25rem;
  font-weight: 600;
}
.card-bot-metrics-extra {
  color: var(--card-bot-gray-color);
}
.card-bot-metrics-description {
  color: var(--card-bot-gray-color);
}
.card-bot-metrics-null {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--card-bot-gray-color);
}

.qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  cursor: pointer;
}

.qr-preview-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.qr-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.qr-preview-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.qr-code {
  width: 400px;
  height: 400px;
}

.iconos-select-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.note-node-text {
  max-width: 100%;
  font-size: 12px;
  white-space: wrap;
  word-break: break-all;
  text-overflow: ellipsis;
}

.note-node-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  max-width: 400px;
  box-sizing: border-box;
  color: var(--dark-color);
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  background-color: #fffbeb !important;
  border: 1px solid #fbd44c !important;
}

.hr-gris {
  width: 100%;
  border-top: 0.5px solid rgb(217, 217, 217);
  height: 1px;
  margin-bottom: 16px;
}

@media (max-width: 1500px) {
  .cards-bots-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 1300px) {
  .cards-bots-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

#date-custom-tabs .ant-tabs-nav-wrap {
  justify-content: center !important;
}

.tags-icon-align {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: start;
}

.columns-sheet {
  padding: 8px 8px 0px 8px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-bottom: 16px;
}

.columns-saveFiles {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-bottom: 16px;
}

.envios-masivos-cron .ant-select-selection-overflow {
  flex: none;
}

.envios-masivos-cron .ant-select-selection-wrap {
  align-items: center;
}

.changelog-timeline .ant-timeline .ant-timeline-item-tail {
  inset-inline-start: 12px !important;
}

.bots-data-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 24px 32px;
  box-sizing: border-box;
  overflow-y: auto;
}

.node-variable-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  margin: 24px 80px;
}
